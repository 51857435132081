import jquery from "jquery";
import {Camera} from "@mediapipe/camera_utils";
import {
    CALIBRATION_DASH_WIDTH,
    CALIBRATION_FACE_CENTER_FROM_X,
    CALIBRATION_FACE_CENTER_FROM_Y,
    CALIBRATION_FACE_CENTER_TO_X,
    CALIBRATION_FACE_CENTER_TO_Y,
    CALIBRATION_FACE_HEIGHT,
    CALIBRATION_FACE_WIDTH,
    CALIBRATION_LEFT_HAND_CENTER_FROM_X,
    CALIBRATION_LEFT_HAND_CENTER_FROM_Y,
    CALIBRATION_LEFT_HAND_CENTER_TO_X,
    CALIBRATION_LEFT_HAND_CENTER_TO_Y,
    CALIBRATION_LEFT_HAND_HEIGHT,
    CALIBRATION_LEFT_HAND_WIDTH,
    CALIBRATION_LINE_WIDTH,
    CALIBRATION_RIGHT_HAND_CENTER_FROM_X,
    CALIBRATION_RIGHT_HAND_CENTER_FROM_Y,
    CALIBRATION_RIGHT_HAND_CENTER_TO_X,
    CALIBRATION_RIGHT_HAND_CENTER_TO_Y,
    CALIBRATION_RIGHT_HAND_HEIGHT,
    CALIBRATION_RIGHT_HAND_WIDTH,
    DISPLAYED_VIDEO_STREAM_HEIGHT,
    DISPLAYED_VIDEO_STREAM_WIDTH,
    GREEN_COLOR,
    INSTRUCTION_CALIBRATION,
    INSTRUCTION_CAMERA_LOADING,
    INSTRUCTION_SIGN,
    INSTRUCTION_SIGN_PREPARATION,
    INSTRUCTION_SIGN_SEARCH,
    INSTRUCTION_SIGN_VALIDATION,
    MEDIA_PIPE_CONFIDENCE,
    MEDIA_PIPE_LEFT_INDEX,
    MEDIA_PIPE_LEFT_PINKY,
    MEDIA_PIPE_LEFT_WRIST,
    MEDIA_PIPE_NOSE,
    MEDIA_PIPE_RIGHT_INDEX,
    MEDIA_PIPE_RIGHT_PINKY,
    MEDIA_PIPE_RIGHT_WRIST,
    RECORDING_FPS,
    RECORDING_STATE,
    RECORDING_TIME_IN_SEC,
    RED_COLOR,
    SECOND_TO_MILLISECONDS,
    VIDEO_RECORDING_COUNTDOWN_IN_SEC,
    WHITE_COLOR
} from "../utils/view_constants.js";
import {
    ALERT_CAMERA_UNAVAILABLE,
    ALERT_ERROR_SEARCH,
    ALERT_NO_EXAMPLE,
    ALERT_NO_MORE_GIF,
    BOUNDING_BOX_FACE_HEIGHT,
    BOUNDING_BOX_FACE_WIDTH,
    BOUNDING_BOX_HAND_HEIGHT,
    BOUNDING_BOX_HAND_WIDTH,
    CORPUS_PICTURES_URL,
    CORPUS_VIDEOS_URL,
    MEDIA_PIPE_LEFT_HAND_LANDMARKS_NAMES,
    MEDIA_PIPE_POSE_LANDMARKS_NAMES,
    MEDIA_PIPE_RIGHT_HAND_LANDMARKS_NAMES
} from "../utils/view_constants";
import {LOG_SEVERITY, LOGGER} from "../utils/log";
import {Holistic} from "@mediapipe/holistic";

export default (window.$ = window.jQuery = jquery);

// --- Events ---
const EVENT_ON_CAMERA_FRAME = new Event("onCameraFrame");
const EVENT_ON_CAMERA_CALIBRATION_OK = new Event("onCameraCalibrationDone");
const EVENT_ON_CAMERA_RECORDING_COUNTDOWN_ELAPSED = new Event("onCameraRecordingCountdownElapsed");
const EVENT_ON_CAMERA_RECORDED = new Event("onCameraRecorded");
const EVENT_ON_SIGN_SEARCH = new Event("onSignSearch");
const EVENT_ON_SIGN_FOUND = new Event("onSignFound");
const EVENT_ON_CLICK_OK_BUTTON = new Event("onClickOkButton");
const EVENT_ON_CLICK_KO_BUTTON = new Event("onClickKoButton");
const EVENT_ON_CLICK_RESET_BUTTON = new Event("onClickResetButton");
const EVENT_NO_MORE_GIF = new Event("noMoreGif");
const EVENT_ON_TRANSLATIONS_SEARCH = new Event("onTranslationsSearch");
const EVENT_ON_TRANSLATIONS = new Event("onTranslations");
const EVENT_ON_TRANSLATION_SELECTED = new Event("onTranslationSelected");
const EVENT_ON_EXAMPLE_SEARCH = new Event("onExampleSearch");
const EVENT_ON_EXAMPLE = new Event("onExample");
const EVENT_NO_EXAMPLE = new Event("noExample");
const EVENT_ON_CLICK_FR_TO_LSFB_BUTTON = new Event("onSwitchFrToLsfb");
const EVENT_ON_CLICK_LSFB_TO_FR_BUTTON = new Event("onSwitchLsfbToFr");


/**
 * @overview This class represents the view.
 */
export class View {

    /**
     * Instantiates the model and the view.
     */
    constructor(presenter) {

        // --- Variables ---

        // Presenter

        this._presenter = presenter;

        // Alerts

        this._alertUI = null;
        this._alertMessageUI = null;
        this._alertCloseButtonUI = null;

        this._alertDisplayTimeout = null;

        // Instructions

        this._instructionsUI = null;

        // Switch translation mode
        this._frToLsfb = null;
        this._lsfbToFr = null;
        this._lsfb_to_fr_div = null;
        this._fr_to_lsfb_div = null;

        // Camera

        this._videoInputUI = null;
        this._videoOutputUI = null;
        this._cameraLoaderUI = null;
        this._videoRecordingCountdownUI = null;
        this._videoRecordingCountdownIconUI = null;
        this._videoRecordingCountdownNumberUI = null;
        this._recordedVideoUI = null;

        this._recordingState = null;
        this._isCameraStopped = true;
        this._isCameraAlreadyStarted = false;
        this._videoInput = null;
        this._videoOutput = null;
        this._canvasContext = null;
        this._camera = null;
        this._landmarks = null;
        this._nosePosition = null;
        this._leftHandPosition = null;
        this._rightHandPosition = null;
        this._holistic = null;
        this._currentRecordingVideoCountdown = 0;
        this._recordedVideo = null;
        this._recordedVideoUrl = null;
        this._recordedFrames = [];

        // Search

        this._signSearchLoaderUI = null;
        this._foundSignGifsUI = null;
        this._resetButtonUI = null;
        this._okButtonUI = null;
        this._koButtonUI = null;

        this._predictions = null;
        this._foundSignGif = null;
        this._gifCursor = 0;
        this._gifCount = 0;

        // Results

        this._waitingTranslationMessageUI = null;
        this._translationSearchLoaderUI = null;
        this._translationsUI = null;
        this._selectedTranslationUI = null;
        this._exampleLoaderUI = null;
        this._examplesUI = null;

        this._translations = null;
        this._selectedTranslation = null;


        // --- Configuration ---

        let self = this;
        jquery(window).ready(function () {

            // 1. Components initialization

            self.initializeAlert();
            self.initializeSwitchTranslationMode();
            self.initializeInstructions();
            self.initializeCamera();
            self.initializeSearch();
            self.initializeResults();

            // 2. UI initialization

            // Alerts

            window.alert = function () {
            }; // Disable JavaScript window alert.

            // Tooltip

            $('#logo').tooltip({
                html: true,
                animated: 'fade',
                placement: 'bottom',
                boundary: 'window',
                sanitize: false,
                title: '<iframe width="560" height="315" src="https://www.youtube.com/embed/mLItsE_AL8k?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            });

            $('*[data-toggle="tooltip"]').tooltip();

            // Log

            let browserInfo = {
                "browser engine": navigator.product,
                "browser version": navigator.appVersion,
                "browser agent": navigator.userAgent,
                "browser platform": navigator.platform,
                "browser language": navigator.language,
                "browser width": window.innerWidth,
                "browser height": window.innerHeight
            };

            LOGGER.log(LOG_SEVERITY.INFO, "Entrypoint", "Entrypoint", "Browser info : " + JSON.stringify(browserInfo));

            // Components

            self.initializeUI();
            LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "UI initialized");
            LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Camera started");

            // 3. Camera lifecycle

            addEventListener(EVENT_ON_CAMERA_FRAME.type, (event) => {
                LOGGER.log(LOG_SEVERITY.DEBUG, "View", "Lifecycle", "Camera frame obtained : NOSE (" + JSON.stringify(self._nosePosition) + "), LEFT HAND (" + JSON.stringify(self._leftHandPosition) + "), RIGHT HAND (" + JSON.stringify(self._rightHandPosition) + ")");
                self.setInstructions(INSTRUCTION_CALIBRATION);
                self.hideCameraLoader();
            });

            addEventListener(EVENT_ON_CAMERA_CALIBRATION_OK.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Camera calibration done");
                self._recordingState = RECORDING_STATE.WILL_RECORD;
                self.setInstructions(INSTRUCTION_SIGN_PREPARATION);
                self.showVideoRecordingCountdown();
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Video recording countdown started");
            });

            addEventListener(EVENT_ON_CAMERA_RECORDING_COUNTDOWN_ELAPSED.type, (event) => {
                self._recordingState = RECORDING_STATE.RECORD;
                self.setInstructions(INSTRUCTION_SIGN);
                self.hideVideoRecordingCountdown();
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Video recording countdown ended");
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Video recording started");
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Landmarks recording started");
                self.recordVideo();
            });

            addEventListener(EVENT_ON_CAMERA_RECORDED.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Video recording ended");
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Landmarks recording ended");
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Landmarks recorded : " + JSON.stringify(self._recordedFrames));
                self._recordingState = RECORDING_STATE.RECORDED;
                self.stopCamera();
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Camera stopped");
                dispatchEvent(EVENT_ON_SIGN_SEARCH);
            });

            // 4. Search lifecycle

            addEventListener(EVENT_ON_SIGN_SEARCH.type, (event) => {
                self.setInstructions(INSTRUCTION_SIGN_SEARCH);
                self.showSignSearchLoader();
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Sign search started");
                self.searchForSign();
            });

            addEventListener(EVENT_ON_SIGN_FOUND.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Sign search ended");
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Sign found : " + self._predictions.toString());
                self.setInstructions(INSTRUCTION_SIGN_VALIDATION);
                self.hideSignSearchLoader();
                self.showRecordedVideo();
                self.showFoundSign();
                self.showResetButton();
                self.showOkButton();
                self.showKoButton();
            });

            addEventListener(EVENT_ON_CLICK_RESET_BUTTON.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Reset button clicked");
                self.resetUI();
                self.closeAlert(); // Closes the alert if it exists.
            });

            addEventListener(EVENT_ON_CLICK_OK_BUTTON.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Sign OK button clicked");
                dispatchEvent(EVENT_ON_TRANSLATIONS_SEARCH);
                self.hideOkButton();
                self.hideKoButton();
            });

            addEventListener(EVENT_ON_CLICK_KO_BUTTON.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Sign KO button clicked");
                self.nextFoundSign();
            });

            addEventListener(EVENT_NO_MORE_GIF.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "No more sign error message shown");
                self.hideKoButton();
                self.alertUser(ALERT_NO_MORE_GIF);
            });

            // 5. Results lifecycle

            addEventListener(EVENT_ON_TRANSLATIONS_SEARCH.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Translation search started");
                self.hideWaitingTranslationMessage();
                self.showTranslationLoader();
                self.translateSign();
            });

            addEventListener(EVENT_ON_TRANSLATIONS.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Translation found : " + self._translations.toString());
                self.hideTranslationLoader();
                self.hideWaitingTranslationMessage();
                self.showTranslations();
            });

            addEventListener(EVENT_ON_TRANSLATION_SELECTED.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Translation selected");
                dispatchEvent(EVENT_ON_EXAMPLE_SEARCH);
                self.highlightTranslation();
                self.closeAlert();
            });

            addEventListener(EVENT_ON_EXAMPLE_SEARCH.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Example search started");
                self.showExampleLoader();
                self.searchExamples();
            });

            addEventListener(EVENT_ON_EXAMPLE.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Example found : " + self.examples.toString());
                self.hideExampleLoader();
                self.hideExamples(); // Clear examples of the previous selected translation.
                self.showExamples(); // New examples of the current selected translation.
            });

            addEventListener(EVENT_NO_EXAMPLE.type, (event) => {
                LOGGER.log(LOG_SEVERITY.WARN, "View", "Lifecycle", "No example found");
                self.hideExampleLoader();
                self.hideExamples();
                self.alertUser(ALERT_NO_EXAMPLE);
            });

            // 6. Switch translation mode
            addEventListener(EVENT_ON_CLICK_FR_TO_LSFB_BUTTON.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Fr to Lsfb button clicked");
                self.switchFrToLsfb();
            });

            addEventListener(EVENT_ON_CLICK_LSFB_TO_FR_BUTTON.type, (event) => {
                LOGGER.log(LOG_SEVERITY.INFO, "View", "Lifecycle", "Lsfb to Fr button clicked");
                self.switchLsfbToFr();
            });

        });
    }

    /**
     * Initializes the UI.
     */
    initializeUI() {

        // Components

        this.hideVideoRecordingCountdown();
        this.hideSignSearchLoader();
        this.hideRecordedVideo();
        this.hideFoundSign();
        this.hideResetButton();
        this.hideOkButton();
        this.hideKoButton();
        this.hideTranslationLoader();
        this.hideTranslations();
        this.hideExampleLoader();
        this.hideExamples();
        this.showCameraLoader();
        this.setInstructions(INSTRUCTION_CAMERA_LOADING);
        this.showInstructions();
        this.showWaitingTranslationMessage();
        this.startCamera();
    }

    // --- Alerts ---

    /**
     * Initializes the alerts.
     */
    initializeAlert() {

        // --- Variables ---

        this._alertCloseButtonUI = $(".alert .close");
        this._alertUI = $(".alert");
        this._alertMessageUI = $(".alert .message");

        // --- Configuration ---

        // Alert close button configuration
        this._alertCloseButtonUI.on("click", () => this.closeAlert());
    }

    /**
     * Displays on the view an (error) alert to the user with a custom message.
     * @param {string} message The custom message.
     */
    alertUser(message) {
        this._alertUI.addClass("show");
        this._alertMessageUI.append(message);
        this._alertDisplayTimeout = setTimeout(() => {
            this.closeAlert();
        }, 10 * SECOND_TO_MILLISECONDS);
    }

    /**
     * Closes the alert on the view and clears the message.
     */
    closeAlert() {
        this._alertUI.removeClass("show");
        this._alertMessageUI.empty();
        clearTimeout(this._alertDisplayTimeout);
    }

    // --- Instructions ---

    /**
     * Initializes the instructions.
     */
    initializeInstructions() {

        // --- Variables ---

        this._instructionsUI = $("#instructions span");
    }

    /**
     * Displays the instructions.
     */
    showInstructions() {
        this._instructionsUI.show();
    }

    /**
     * Hides the instructions.
     */
    hideInstructions() {
        this._instructionsUI.hide();
    }

    /**
     * Sets the instructions text with the new one given.
     * @param newText The given new text.
     */
    setInstructions(newText) {
        this._instructionsUI.text(newText);
    }

    initializeSwitchTranslationMode() {
        this._frToLsfb = $('#fr_to_lsfb_href');
        this._lsfbToFr = $('#lsfb_to_fr_href');
        this._lsfb_to_fr_div = $('#lsfb_to_fr_div');
        this._fr_to_lsfb_div = $('#fr_to_lsfb_div');

        this._frToLsfb.click(() => dispatchEvent(EVENT_ON_CLICK_FR_TO_LSFB_BUTTON));
        this._lsfbToFr.click(() => dispatchEvent(EVENT_ON_CLICK_LSFB_TO_FR_BUTTON));

        this.switchLsfbToFr();
    }

    // --- Camera ---

    /**
     * Initializes the camera options and the related MediaPipe environment in order to enrich the video stream and capture the sign.
     */
    initializeCamera() {

        // --- Variables ---

        // Sign recognizer
        this._holistic = new Holistic({
            locateFile: (file) => {
                return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
            }
        });

        // Camera loader
        this._cameraLoaderUI = $("#camera-loader");

        // Video recording countdown
        this._videoRecordingCountdownUI = $("#video-recording-countdown");
        this._videoRecordingCountdownIconUI = $("#video-recording-countdown-icon");
        this._videoRecordingCountdownNumberUI = $("#video-recording-countdown-number");

        // Input stream
        this._videoInputUI = $(".video-input");
        this._videoInput = this._videoInputUI[0];

        // Output stream
        this._videoOutputUI = $(".video-output");
        this._videoOutput = this._videoOutputUI[0];

        // Camera
        this._camera = new Camera(this._videoInput, {
            onFrame: async () => {
                await this._holistic.send({image: this._videoInput});
            },
            width: DISPLAYED_VIDEO_STREAM_WIDTH,
            height: DISPLAYED_VIDEO_STREAM_HEIGHT
        });

        // Recorded video
        this._recordedVideoUI = $("#recorded-video");

        // --- Configuration ---

        // Sign recognizer
        this._holistic.setOptions({
            modelComplexity: 1,
            upperBodyOnly: true,
            smoothLandmarks: true,
            minDetectionConfidence: MEDIA_PIPE_CONFIDENCE,
            minTrackingConfidence: MEDIA_PIPE_CONFIDENCE
        });

        // Output stream
        this._canvasContext = this._videoOutput.getContext('2d');
        this.resizeCamera(DISPLAYED_VIDEO_STREAM_WIDTH, DISPLAYED_VIDEO_STREAM_HEIGHT);
        this._holistic.onResults((poseFrame) => this._onCameraFrame(poseFrame));
    }

    /**
     * Resizing the displayed camera video stream.
     * CAREFUL! Only the displayed style width is resized. The real video stream remains 1920x1440.
     * this._videoOutput.style.width !== this._videoOutput.width
     * @param newWidth The new with to resize with.
     * @param newHeight The new height to resize with.
     */
    resizeCamera(newWidth, newHeight) {
        this._videoOutput.style.width = newWidth + "px";
        this._videoOutput.style.height = newHeight + "px";
    }

    /**
     * Displays the camera loader.
     */
    showCameraLoader() {
        this._cameraLoaderUI.show();
    }

    /**
     * Hides the camera loader.
     */
    hideCameraLoader() {
        this._cameraLoaderUI.hide();
    }

    /**
     * Starts the camera.
     */
    startCamera() {
        if(!this._isCameraAlreadyStarted){
            this._isCameraAlreadyStarted = true;
            this._camera.start().catch(() => {
                this.alertUser(ALERT_CAMERA_UNAVAILABLE);
                LOGGER.log(LOG_SEVERITY.ERROR, "View", "startCamera", "Camera unavailable");
            });
        }
        this._isCameraStopped = false;
        this._recordingState = RECORDING_STATE.NO_RECORD;
        this._videoOutputUI.show();
    }

    /**
     * Stops the camera.
     */
    stopCamera() {
        this._isCameraStopped = true;
        // this._camera.stop();
        this._clearCamera();
        this._videoOutputUI.hide();
    }

    /**
     * Reacts (enrich, record, ... the video stream) on a new frame coming from the camera.
     * @param frame The new frame coming from the camera.
     */
    _onCameraFrame(frame) {

        let landmarks = [];

        if (!this._isCameraStopped) {

            if (this._recordingState === RECORDING_STATE.NO_RECORD) {
                dispatchEvent(EVENT_ON_CAMERA_FRAME);
            }

            // Pose landmarks
            if (frame.hasOwnProperty("poseLandmarks")) {

                // Nose position
                this._nosePosition =
                    {
                        x: frame.poseLandmarks[MEDIA_PIPE_NOSE].x * this._videoOutput.width,
                        y: frame.poseLandmarks[MEDIA_PIPE_NOSE].y * this._videoOutput.height
                    };

                // Left hand position
                this._leftHandPosition =
                    {
                        x: ((frame.poseLandmarks[MEDIA_PIPE_RIGHT_WRIST].x + frame.poseLandmarks[MEDIA_PIPE_RIGHT_PINKY].x + frame.poseLandmarks[MEDIA_PIPE_RIGHT_INDEX].x) / 3) * this._videoOutput.width,
                        y: ((frame.poseLandmarks[MEDIA_PIPE_RIGHT_WRIST].y + frame.poseLandmarks[MEDIA_PIPE_RIGHT_PINKY].y + frame.poseLandmarks[MEDIA_PIPE_RIGHT_INDEX].y) / 3) * this._videoOutput.height
                    };

                // Right hand position
                this._rightHandPosition =
                    {
                        x: ((frame.poseLandmarks[MEDIA_PIPE_LEFT_WRIST].x + frame.poseLandmarks[MEDIA_PIPE_LEFT_PINKY].x + frame.poseLandmarks[MEDIA_PIPE_LEFT_INDEX].x) / 3) * this._videoOutput.width,
                        y: ((frame.poseLandmarks[MEDIA_PIPE_LEFT_WRIST].y + frame.poseLandmarks[MEDIA_PIPE_LEFT_PINKY].y + frame.poseLandmarks[MEDIA_PIPE_LEFT_INDEX].y) / 3) * this._videoOutput.height
                    };

                // Format
                for (let i = 0; i < frame.poseLandmarks.length; i++) {
                    let landmark = frame.poseLandmarks[i];
                    landmarks.push({
                        name: MEDIA_PIPE_POSE_LANDMARKS_NAMES[i],
                        x: landmark.x,
                        y: landmark.y,
                        z: landmark.z
                    });
                }
            }

            // Left hands landmarks
            if (frame.hasOwnProperty("leftHandLandmarks")) {

                // Format
                for (let i = 0; i < frame.leftHandLandmarks.length; i++) {
                    let landmark = frame.leftHandLandmarks[i];
                    landmarks.push({
                        name: MEDIA_PIPE_LEFT_HAND_LANDMARKS_NAMES[i],
                        x: landmark.x,
                        y: landmark.y,
                        z: landmark.z
                    });
                }
            }

            // Right hands landmarks
            if (frame.hasOwnProperty("rightHandLandmarks")) {

                // Format
                for (let i = 0; i < frame.rightHandLandmarks.length; i++) {
                    let landmark = frame.rightHandLandmarks[i];
                    landmarks.push({
                        name: MEDIA_PIPE_RIGHT_HAND_LANDMARKS_NAMES[i],
                        x: landmark.x,
                        y: landmark.y,
                        z: landmark.z
                    });
                }
            }

            // Record landmarks

            this._landmarks = landmarks;

            if (this._recordingState === RECORDING_STATE.RECORD) {
                this._recordedFrames.push(this._landmarks);
            }

            // Setup
            this._onCameraFrameSetup();

            // Layer : Mirror (reversing the image in order to reproduce the mirror effect)
            this._onCameraFrameMirror();

            // Layer : Video stream
            this._onCameraFrameDrawVideo(frame.image);

            if (this._recordingState === RECORDING_STATE.NO_RECORD) {
                // Layer : Calibration boxes
                this._onCameraFrameDrawCalibrationBoxes();

                // Layer : Bounding boxes
                this._onCameraFrameDrawBoundingBoxes();

                // Checking calibration
                this.checkCalibration();
            }

            // Cleanup
            this._onCameraFrameTeardown();
        }
    }

    /**
     * Clears the camera canvas.
     */
    _clearCamera() {
        this._canvasContext.clearRect(0, 0, this._videoOutput.width, this._videoOutput.height);
    }

    /**
     * Setups the video stream displaying.
     */
    _onCameraFrameSetup() {
        this._canvasContext.save();
        this._clearCamera();
    }

    /**
     * Reverses the video stream in order to reproduce the mirror effects.
     */
    _onCameraFrameMirror() {
        this._canvasContext.translate(this._videoOutput.width, 0);
        this._canvasContext.scale(-1, 1);
    }

    /**
     * Displays the video stream in the canvas.
     * @param videoImage The video stream.
     */
    _onCameraFrameDrawVideo(videoImage) {
        this._canvasContext.drawImage(videoImage, 0, 0, this._videoOutput.width, this._videoOutput.height);
    }

    /**
     * Draws the calibration boxes above the video stream.
     */
    _onCameraFrameDrawCalibrationBoxes() {
        // Setup options
        this._canvasContext.strokeStyle = WHITE_COLOR;
        this._canvasContext.lineWidth = CALIBRATION_LINE_WIDTH;
        this._canvasContext.setLineDash([CALIBRATION_DASH_WIDTH]);

        // Face drawing
        this.drawEllipse(
            (CALIBRATION_FACE_CENTER_FROM_X + CALIBRATION_FACE_CENTER_TO_X) / 2,
            (CALIBRATION_FACE_CENTER_FROM_Y + CALIBRATION_FACE_CENTER_TO_Y) / 2,
            CALIBRATION_FACE_WIDTH,
            CALIBRATION_FACE_HEIGHT,
            WHITE_COLOR);

        // Left hand drawing
        this.drawEllipse(
            (CALIBRATION_RIGHT_HAND_CENTER_FROM_X + CALIBRATION_RIGHT_HAND_CENTER_TO_X) / 2,
            (CALIBRATION_RIGHT_HAND_CENTER_FROM_Y + CALIBRATION_RIGHT_HAND_CENTER_TO_Y) / 2,
            CALIBRATION_RIGHT_HAND_WIDTH,
            CALIBRATION_RIGHT_HAND_HEIGHT,
            WHITE_COLOR);

        // Right hand drawing
        this.drawEllipse(
            (CALIBRATION_LEFT_HAND_CENTER_FROM_X + CALIBRATION_LEFT_HAND_CENTER_TO_X) / 2,
            (CALIBRATION_LEFT_HAND_CENTER_FROM_Y + CALIBRATION_LEFT_HAND_CENTER_TO_Y) / 2,
            CALIBRATION_LEFT_HAND_WIDTH,
            CALIBRATION_LEFT_HAND_HEIGHT,
            WHITE_COLOR);

        // Reset options
        this._canvasContext.setLineDash([]);
    }

    /**
     * Draws the bounding boxes above the video stream for the nose, the left and the right hand.
     */
    _onCameraFrameDrawBoundingBoxes() {

        if (this._nosePosition !== null && this._leftHandPosition !== null && this._rightHandPosition !== null) {

            // Options
            this._canvasContext.lineWidth = CALIBRATION_LINE_WIDTH;
            let currentColor = WHITE_COLOR;

            // Face drawing
            if (this._nosePosition.x >= CALIBRATION_FACE_CENTER_FROM_X && this._nosePosition.x <= CALIBRATION_FACE_CENTER_TO_X
                && this._nosePosition.y >= CALIBRATION_FACE_CENTER_FROM_Y && this._nosePosition.y <= CALIBRATION_FACE_CENTER_TO_Y) {
                currentColor = GREEN_COLOR;
            } else {
                currentColor = RED_COLOR;
            }
            this.drawEllipse(this._nosePosition.x, this._nosePosition.y, BOUNDING_BOX_FACE_WIDTH, BOUNDING_BOX_FACE_HEIGHT, currentColor);

            // Left hand drawing
            if (this._leftHandPosition.x >= CALIBRATION_LEFT_HAND_CENTER_FROM_X && this._leftHandPosition.x <= CALIBRATION_LEFT_HAND_CENTER_TO_X
                && this._leftHandPosition.y >= CALIBRATION_LEFT_HAND_CENTER_FROM_Y && this._leftHandPosition.y <= CALIBRATION_LEFT_HAND_CENTER_TO_Y) {
                currentColor = GREEN_COLOR;
            } else {
                currentColor = RED_COLOR;
            }
            this.drawEllipse(this._leftHandPosition.x, this._leftHandPosition.y, BOUNDING_BOX_HAND_WIDTH, BOUNDING_BOX_HAND_HEIGHT, currentColor);

            // Right hand drawing
            if (this._rightHandPosition.x >= CALIBRATION_RIGHT_HAND_CENTER_FROM_X && this._rightHandPosition.x <= CALIBRATION_RIGHT_HAND_CENTER_TO_X
                && this._rightHandPosition.y >= CALIBRATION_RIGHT_HAND_CENTER_FROM_Y && this._rightHandPosition.y <= CALIBRATION_RIGHT_HAND_CENTER_TO_Y) {
                currentColor = GREEN_COLOR;
            } else {
                currentColor = RED_COLOR;
            }
            this.drawEllipse(this._rightHandPosition.x, this._rightHandPosition.y, BOUNDING_BOX_HAND_WIDTH, BOUNDING_BOX_HAND_HEIGHT, currentColor);
        }
    }

    /**
     * Draws and ellipse on a canvas.
     * @param x The x position of the ellipse.
     * @param y The y position of the ellipse.
     * @param width The width of the ellipse.
     * @param height The height of the ellipse.
     * @param color The color of the ellipse stroke.
     */
    drawEllipse(x, y, width, height, color) {
        this._canvasContext.beginPath();
        this._canvasContext.strokeStyle = color;
        this._canvasContext.ellipse(x, y, width, height, 0, 0, 2 * Math.PI);
        this._canvasContext.stroke();
    }

    /**
     * Checks of the user in front of the camera is well positioned compared to the nose, left and right hand positions.
     */
    checkCalibration() {
        if (this._nosePosition !== null && this._leftHandPosition !== null && this._rightHandPosition !== null) {
            if (this._nosePosition.x >= CALIBRATION_FACE_CENTER_FROM_X && this._nosePosition.x <= CALIBRATION_FACE_CENTER_TO_X
                && this._nosePosition.y >= CALIBRATION_FACE_CENTER_FROM_Y && this._nosePosition.y <= CALIBRATION_FACE_CENTER_TO_Y
                && this._leftHandPosition.x >= CALIBRATION_LEFT_HAND_CENTER_FROM_X && this._leftHandPosition.x <= CALIBRATION_LEFT_HAND_CENTER_TO_X
                && this._leftHandPosition.y >= CALIBRATION_LEFT_HAND_CENTER_FROM_Y && this._leftHandPosition.y <= CALIBRATION_LEFT_HAND_CENTER_TO_Y
                && this._rightHandPosition.x >= CALIBRATION_RIGHT_HAND_CENTER_FROM_X && this._rightHandPosition.x <= CALIBRATION_RIGHT_HAND_CENTER_TO_X
                && this._rightHandPosition.y >= CALIBRATION_RIGHT_HAND_CENTER_FROM_Y && this._rightHandPosition.y <= CALIBRATION_RIGHT_HAND_CENTER_TO_Y) {
                dispatchEvent(EVENT_ON_CAMERA_CALIBRATION_OK);
            }
        }
    }

    /**
     * Ends the video stream displaying.
     */
    _onCameraFrameTeardown() {
        this._canvasContext.restore();
    }

    /**
     * Starts the countdown shown before recording the video.
     */
    showVideoRecordingCountdown() {
        this._currentRecordingVideoCountdown = VIDEO_RECORDING_COUNTDOWN_IN_SEC;

        this._videoRecordingCountdownUI.css('display', 'flex');
        this._videoRecordingCountdownNumberUI.text(this._currentRecordingVideoCountdown);
        this._videoRecordingCountdownIconUI.css("animation", "countdown " + this._currentRecordingVideoCountdown + "s linear infinite forwards");

        let self = this;

        let countdownInterval = setInterval(function () { // Decreases the countdown every second.
            self._currentRecordingVideoCountdown = --self._currentRecordingVideoCountdown <= 0 ? 0 : self._currentRecordingVideoCountdown;
            self._videoRecordingCountdownNumberUI.text(self._currentRecordingVideoCountdown);
        }, 1 * SECOND_TO_MILLISECONDS);

        let countdownTimeout = setTimeout(function () { // Initiates the recording on time elapsed.
            dispatchEvent(EVENT_ON_CAMERA_RECORDING_COUNTDOWN_ELAPSED);
            clearInterval(countdownInterval);
            clearTimeout(countdownTimeout);
        }, (VIDEO_RECORDING_COUNTDOWN_IN_SEC - 0.1) * SECOND_TO_MILLISECONDS);
    }

    /**
     * Stops the video recording countdown.
     */
    hideVideoRecordingCountdown() {
        this._currentRecordingVideoCountdown = 0;
        this._videoRecordingCountdownUI.hide();
    }

    /**
     * Records the video from the user camera.
     */
    recordVideo() {
        let videoStream = this._videoOutput.captureStream(RECORDING_FPS);
        let mediaRecorder = new MediaRecorder(videoStream, {mimeType: 'video/webm'});
        const recordedChunks = [];

        // Start recording
        mediaRecorder.start();

        // Recording
        this._recordingState = RECORDING_STATE.RECORD;
        mediaRecorder.addEventListener('dataavailable', function (e) {
            if (e.data.size > 0) {
                recordedChunks.push(e.data);
            }
        });

        let self = this;

        // Stop recording
        setTimeout(() => {
            mediaRecorder.stop();
        }, RECORDING_TIME_IN_SEC * SECOND_TO_MILLISECONDS);

        mediaRecorder.addEventListener('stop', function () {
            self._recordedVideo = new Blob(recordedChunks);
            dispatchEvent(EVENT_ON_CAMERA_RECORDED);
        });
    }

    // -- Search --

    /**
     * Initializes the sign search.
     */
    initializeSearch() {

        // -- Variables --

        this._foundSignUI = $("#found-sign");
        this._foundSignGifUI = $("#found-sign-gif")[0];
        this._signSearchLoaderUI = $("#sign-search-loader");
        this._resetButtonUI = $("#reset-button");
        this._okButtonUI = $("#ok-button");
        this._koButtonUI = $("#ko-button");

        // -- Configuration --

        this._resetButtonUI.click(() => dispatchEvent(EVENT_ON_CLICK_RESET_BUTTON));

        this._okButtonUI.click(() => dispatchEvent(EVENT_ON_CLICK_OK_BUTTON));

        this._koButtonUI.click(() => dispatchEvent(EVENT_ON_CLICK_KO_BUTTON));
    }

    /**
     * Searches for a sign predication related to the current recorded video.
     */
    searchForSign() {
        let self = this;
        this._presenter.getPredictionsByFrames(this._recordedFrames).then((predictions) => {
            self._predictions = predictions;
            self._gifCursor = 0;
            self._gifCount = predictions.length;
            dispatchEvent(EVENT_ON_SIGN_FOUND);
        }).catch((error) => {
            this.alertUser(ALERT_ERROR_SEARCH);
        });
    }

    /**
     * Shows the loader when the sign search is operating.
     */
    showSignSearchLoader() {
        this._signSearchLoaderUI.css('display', 'flex');
    }

    /**
     * Hides the sign search loader.
     */
    hideSignSearchLoader() {
        this._signSearchLoaderUI.hide();
    }

    /**
     * Shows the recorded video.
     */
    showRecordedVideo() {
        let recordedVideoTag = this._recordedVideoUI[0].firstElementChild;
        this._recordedVideoUrl = URL.createObjectURL(this._recordedVideo);
        this._recordedVideoUI[0].firstElementChild.setAttribute('src', this._recordedVideoUrl);
        let self = this;
        recordedVideoTag.load();
        recordedVideoTag.onloadeddata = function () {
            recordedVideoTag.play();
            self._recordedVideoUI.css('display', 'flex');
        }
    }

    /**
     * Hides the recorded video.
     */
    hideRecordedVideo() {
        let recordedVideoTag = this._recordedVideoUI[0].firstElementChild;
        recordedVideoTag.pause();
        recordedVideoTag.currentTime = 0;
        recordedVideoTag.removeAttribute('src'); // Empty source
        URL.revokeObjectURL(this._recordedVideoUrl);
        this._recordedVideo = null;
        this._recordedFrames = [];
        this._recordedVideoUI.hide();
    }

    /**
     * Shows found sign gif.
     */
    showFoundSign() {
        let self = this;
        this._foundSignUI.css({'filter': 'blur(5px)', 'transition': 'all 0.5s ease-out'});
        this._presenter.getGif(this._predictions[this._gifCursor]).then((gif) => {
            self._foundSignGif = gif;
            self._foundSignGifUI.setAttribute('src', CORPUS_PICTURES_URL + self._foundSignGif.getUrl());
            self._foundSignGifUI.addEventListener('load', (event) => {
                self._foundSignUI.css('display', 'flex');
                self._foundSignUI.css({'filter': 'blur(0px)'});
                self._foundSignGifUI.removeEventListener('load', this);
            });
        }).catch((error) => {
            this.alertUser(ALERT_ERROR_SEARCH);
        });
    }

    /**
     * Hides found sign gif.
     */
    hideFoundSign() {
        this._foundSignGif = null;
        this._foundSignGifUI.setAttribute('src', '#');
        this._foundSignUI.hide();
    }

    /**
     * Updates and displays the next found sign until it remains GIFs.
     */
    nextFoundSign() {
        if (this._gifCursor === this._gifCount - 1) {
            dispatchEvent(EVENT_NO_MORE_GIF);
        } else {
            this._gifCursor++;
            this.showFoundSign();
        }
    }

    /**
     * Shows the reset button.
     */
    showResetButton() {
        this._resetButtonUI.show();
    }

    /**
     * Hides the reset button.
     */
    hideResetButton() {
        this._resetButtonUI.hide();
    }

    /**
     * Shows the OK button.
     */
    showOkButton() {
        this._okButtonUI.show();
    }

    /**
     * Hides the OK button.
     */
    hideOkButton() {
        this._okButtonUI.hide();
    }

    /**
     * Shows the OK button.
     */
    showKoButton() {
        this._koButtonUI.show();
    }


    /**
     * Hides the OK button.
     */
    hideKoButton() {
        this._koButtonUI.hide();
    }

    /**
     * Resets the UI in regard of a new search.
     */
    resetUI() {
        this.initializeUI();
    }

    // -- Results --

    /**
     * Initializes the search results.
     */
    initializeResults() {

        // -- Variables --

        this._waitingTranslationMessageUI = $("#waiting-translation-message");
        this._translationSearchLoaderUI = $("#translation-search-loader");
        this._translationsUI = $("#translations");
        this._exampleLoaderUI = $("#example-loader");
        this._examplesUI = $("#examples");
    }

    /**
     * Translates the current selected gloss.
     */
    translateSign() {
        let self = this;
        this._presenter.getTranslations(this._predictions[this._gifCursor]).then((translations) => {
            self._translations = translations;
            dispatchEvent(EVENT_ON_TRANSLATIONS);
        }).catch((error) => {
            this.alertUser(ALERT_ERROR_SEARCH);
        });
    }

    /**
     * Shows the translations.
     */
    showWaitingTranslationMessage() {
        this._waitingTranslationMessageUI.show();
    }

    /**
     * Hides the OK button.
     */
    hideWaitingTranslationMessage() {
        this._waitingTranslationMessageUI.hide();
    }

    /**
     * Shows the translations.
     */
    showTranslationLoader() {
        this._translationSearchLoaderUI.css('display', 'flex');
    }

    /**
     * Hides the OK button.
     */
    hideTranslationLoader() {
        this._translationSearchLoaderUI.hide();
    }

    /**
     * Shows the translations.
     */
    showTranslations() {
        let self = this;
        this._translations.forEach((translation) => {
            let wordUI = $('<span class="word frequency-' + this._getStyledFrequency(translation) + '">' + translation.getWord().getText() + '</span>');
            wordUI.click(() => {
                self._selectedTranslation = translation;
                self._selectedTranslationUI = wordUI;
                dispatchEvent(EVENT_ON_TRANSLATION_SELECTED);
            });
            this._translationsUI.append(wordUI);
        });
        this._translationsUI.show();
    }

    /**
     * Determines the styled frequency of a translation based on its numeric effective frequency.
     * @param translation {Translation} The given word.
     * @return {Number} The styled frequency
     */
    _getStyledFrequency(translation) {
        let frequency = 4;
        switch (true) {
            case translation.getFrequency() <= 0.25 :
                break;
            case translation.getFrequency() > 0.25 && translation.getFrequency() <= 0.5 :
                frequency = 3;
                break;
            case translation.getFrequency() > 0.5 && translation.getFrequency() <= 0.75 :
                frequency = 2;
                break;
            case translation.getFrequency() > 0.75 && translation.getFrequency() <= 1 :
                frequency = 1;
                break;
        }
        return frequency;
    }

    /**
     * Hides the OK button.
     */
    hideTranslations() {
        this._translationsUI.empty();
        this._translationsUI.hide();
    }

    /**
     * Highlights the selected translation.
     */
    highlightTranslation() {
        this._translationsUI.children("span").each(function () {
            $(this).removeClass("highlight");
        }); // Removes the highlight on all the translations.
        this._selectedTranslationUI.addClass("highlight"); // Highlights the selected one.
    }

    /**
     * Shows the example loader.
     */
    showExampleLoader() {
        this._exampleLoaderUI.show();
    }

    /**
     * Hides the example loader.
     */
    hideExampleLoader() {
        this._exampleLoaderUI.hide();
    }

    /**
     * Search for examples related to the selected translation.
     */
    searchExamples() {
        let self = this;
        this._presenter.getHighlightedExamples(this._selectedTranslation).then((examples) => {
            if (examples.length > 0) {
                this.examples = examples;
                dispatchEvent(EVENT_ON_EXAMPLE);
            } else {
                dispatchEvent(EVENT_NO_EXAMPLE);
            }
        }).catch((error) => {
            this.alertUser(ALERT_ERROR_SEARCH);
        });
    }

    /**
     * Shows contextual examples related to the selected translation.
     */
    showExamples() {
        let self = this;
        this.examples.forEach((example) => {
            let exampleUI = $('' +
                '<div class="d-flex line example">' +
                '<div class="flex-fill panel left-panel">' +
                '<video src="' + CORPUS_VIDEOS_URL + example.getVideo().getUrl() + '#t=' + example.getStartTime() + ',' + example.getEndTime() + '" type="video/mp4" class="video" controls></video>' +
                '</div>' +
                '<div class="flex-fill panel right-panel">' +
                '<span class="sentence">' +
                example.getSentence().getText() +
                '</span>' +
                '</div>' +
                '</div>'
            );
            self._examplesUI.append(exampleUI);
        });
        this._examplesUI.show();
    }

    /**
     * Hides the contextual examples.
     */
    hideExamples() {
        this._examplesUI.empty();
        this._examplesUI.hide();
    }

    switchFrToLsfb() {
        this._frToLsfb.hide();
        this._fr_to_lsfb_div.show();

        this._lsfbToFr.show();
        this._lsfb_to_fr_div.hide();
    }

    switchLsfbToFr() {
        this._frToLsfb.show();
        this._fr_to_lsfb_div.hide();

        this._lsfbToFr.hide();
        this._lsfb_to_fr_div.show();
        
    }
}
