/**
 * @overview This file contains all the constants needed for the view.
 */

// --- Conversion ---

export const SECOND_TO_MILLISECONDS = 1000;

// --- Camera ---

export const ORIGINAL_VIDEO_STREAM_WIDTH = 1920;
export const ORIGINAL_VIDEO_STREAM_HEIGHT = 1440;
export const RESIZE_VIDEO_RATIO_WIDTH = 3;
export const RESIZE_VIDEO_RATIO_HEIGHT = 4;
export const DISPLAYED_VIDEO_STREAM_WIDTH = ORIGINAL_VIDEO_STREAM_WIDTH/RESIZE_VIDEO_RATIO_WIDTH; // 1920px / 3 = 640px
export const DISPLAYED_VIDEO_STREAM_HEIGHT = ORIGINAL_VIDEO_STREAM_HEIGHT/RESIZE_VIDEO_RATIO_HEIGHT; // 1440px / 4 = 360px

// --- Video recording --

let videoRecordingCountdownInSec = 5;
if (process.env !== undefined && process.env.MODE === 'test') {
    videoRecordingCountdownInSec = 10;
}
export const VIDEO_RECORDING_COUNTDOWN_IN_SEC = videoRecordingCountdownInSec;
export const RECORDING_STATE = {
    NO_RECORD: 0,
    WILL_RECORD: 1,
    RECORD: 2,
    RECORDED: 3
};
export const RECORDING_TIME_IN_SEC = 3;
export const RECORDING_FPS = 30;

// --- Calibration ---

export const CALIBRATION_LINE_WIDTH = 10/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_DASH_WIDTH = 10/RESIZE_VIDEO_RATIO_HEIGHT;

// Face
export const BOUNDING_BOX_FACE_WIDTH = 200/RESIZE_VIDEO_RATIO_WIDTH;
export const BOUNDING_BOX_FACE_HEIGHT = 400/RESIZE_VIDEO_RATIO_HEIGHT;
export const CALIBRATION_FACE_WIDTH = 200/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_FACE_HEIGHT = 400/RESIZE_VIDEO_RATIO_HEIGHT;
export const CALIBRATION_FACE_CENTER_FROM_X = 850/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_FACE_CENTER_FROM_Y = 450/RESIZE_VIDEO_RATIO_HEIGHT;
export const CALIBRATION_FACE_CENTER_TO_X = 1050/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_FACE_CENTER_TO_Y = 850/RESIZE_VIDEO_RATIO_HEIGHT;
export const MEDIA_PIPE_NOSE = 0;

// Hands
export const BOUNDING_BOX_HAND_WIDTH = 125/RESIZE_VIDEO_RATIO_WIDTH;
export const BOUNDING_BOX_HAND_HEIGHT = 175/RESIZE_VIDEO_RATIO_HEIGHT;

// Left hand
export const CALIBRATION_LEFT_HAND_WIDTH = 150/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_LEFT_HAND_HEIGHT = 200/RESIZE_VIDEO_RATIO_HEIGHT;
export const CALIBRATION_LEFT_HAND_CENTER_FROM_X = 275/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_LEFT_HAND_CENTER_FROM_Y = 900/RESIZE_VIDEO_RATIO_HEIGHT;
export const CALIBRATION_LEFT_HAND_CENTER_TO_X = 425/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_LEFT_HAND_CENTER_TO_Y = 1100/RESIZE_VIDEO_RATIO_HEIGHT;

// Right hand
export const CALIBRATION_RIGHT_HAND_WIDTH = 150/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_RIGHT_HAND_HEIGHT = 200/RESIZE_VIDEO_RATIO_HEIGHT;
export const CALIBRATION_RIGHT_HAND_CENTER_FROM_X = 1425/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_RIGHT_HAND_CENTER_FROM_Y = 900/RESIZE_VIDEO_RATIO_HEIGHT;
export const CALIBRATION_RIGHT_HAND_CENTER_TO_X = 1575/RESIZE_VIDEO_RATIO_WIDTH;
export const CALIBRATION_RIGHT_HAND_CENTER_TO_Y = 1100/RESIZE_VIDEO_RATIO_HEIGHT;

// --- MediaPipe ---

export const MEDIA_PIPE_CONFIDENCE = 0.5;
export const MEDIA_PIPE_POSE_LANDMARKS_NAMES = ["NOSE", "LEFT_EYE_INNER", "LEFT_EYE", "LEFT_EYE_OUTER", "RIGHT_EYE_INNER", "RIGHT_EYE", "RIGHT_EYE_OUTER", "LEFT_EAR", "RIGHT_EAR", "MOUTH_LEFT", "MOUTH_RIGHT", "LEFT_SHOULDER", "RIGHT_SHOULDER", "LEFT_ELBOW", "RIGHT_ELBOW", "LEFT_WRIST", "RIGHT_WRIST", "LEFT_PINKY", "RIGHT_PINKY", "LEFT_INDEX", "RIGHT_INDEX", "LEFT_THUMB", "RIGHT_THUMB", "LEFT_HIP", "RIGHT_HIP", "LEFT_KNEE", "RIGHT_KNEE", "LEFT_ANKLE", "RIGHT_ANKLE", "LEFT_HEEL", "RIGHT_HEEL", "LEFT_FOOT_INDEX", "RIGHT_FOOT_INDEX"];
export const MEDIA_PIPE_LEFT_HAND_LANDMARKS_NAMES = ["LEFT_WRIST", "LEFT_THUMB_CMP", "LEFT_THUMB_MCP", "LEFT_THUMB_IP", "LEFT_THUMB_TIP", "LEFT_INDEX_FINGER_MCP", "LEFT_INDEX_FINGER_PIP", "LEFT_INDEX_FINGER_DIP", "LEFT_INDEX_FINGER_TIP", "LEFT_MIDDLE_FINGER_MCP", "LEFT_MIDDLE_FINGER_PIP", "LEFT_MIDDLE_FINGER_DIP", "LEFT_MIDDLE_FINGER_TIP", "LEFT_RING_FINGER_MCP", "LEFT_RING_FINGER_PIP", "LEFT_RING_FINGER_DIP", "LEFT_RING_FINGER_TIP", "LEFT_PINKY_MCP", "LEFT_PINKY_PIP", "LEFT_PINKY_DIP", "LEFT_PINKY_TIP"];
export const MEDIA_PIPE_RIGHT_HAND_LANDMARKS_NAMES = ["RIGHT_WRIST", "RIGHT_THUMB_CMP", "RIGHT_THUMB_MCP", "RIGHT_THUMB_IP", "RIGHT_THUMB_TIP", "RIGHT_INDEX_FINGER_MCP", "RIGHT_INDEX_FINGER_PIP", "RIGHT_INDEX_FINGER_DIP", "RIGHT_INDEX_FINGER_TIP", "RIGHT_MIDDLE_FINGER_MCP", "RIGHT_MIDDLE_FINGER_PIP", "RIGHT_MIDDLE_FINGER_DIP", "RIGHT_MIDDLE_FINGER_TIP", "RIGHT_RING_FINGER_MCP", "RIGHT_RING_FINGER_PIP", "RIGHT_RING_FINGER_DIP", "RIGHT_RING_FINGER_TIP", "RIGHT_PINKY_MCP", "RIGHT_PINKY_PIP", "RIGHT_PINKY_DIP", "RIGHT_PINKY_TIP"];
export const MEDIA_PIPE_RIGHT_WRIST = 16;
export const MEDIA_PIPE_RIGHT_PINKY = 18;
export const MEDIA_PIPE_RIGHT_INDEX = 20;
export const MEDIA_PIPE_LEFT_WRIST = 15;
export const MEDIA_PIPE_LEFT_PINKY = 17;
export const MEDIA_PIPE_LEFT_INDEX = 19;

// --- Color ---

export const WHITE_COLOR = 'white';
export const RED_COLOR = 'red';
export const GREEN_COLOR = 'green';

// --- Instructions --

export const INSTRUCTION_CAMERA_LOADING = "Chargement de votre caméra ...";
export const INSTRUCTION_CALIBRATION = "Calibrez-vous. Placez votre visage et vos mains dans les pointillés.";
export const INSTRUCTION_SIGN_PREPARATION = "Préparez-vous à signer. Débutez d'une position neutre.";
export const INSTRUCTION_SIGN = "Signez !";
export const INSTRUCTION_SIGN_SEARCH = "Nous analysons votre signe ...";
export const INSTRUCTION_SIGN_VALIDATION = "Validez le signe qui correspond à votre signe.";

// --- Alerts ---

export const ALERT_CAMERA_UNAVAILABLE = "Votre caméra n'est pas disponible ou est utilisée par une autre application. Merci de réessayer en fermant les autres applications.";
export const ALERT_NO_MORE_GIF = "Aucun signe ne correspond à votre recherche. Réessayez.";
export const ALERT_NO_EXAMPLE = "Il n'existe pas encore d'exemples pour ce mot.";
export const ALERT_ERROR_SEARCH = "Erreur lors de la recherche. Réessayez.";

// --- URL ---
export const CORPUS_PICTURES_URL = "https://corpus-lsfb.be/img/pictures/";
export const CORPUS_VIDEOS_URL = "https://corpus-lsfb.be/";
