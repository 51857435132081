import {INVALID_OBJECT_FORMAT} from "../utils/model_constants.js";
import {Video} from "./video.js";
import {Sentence} from "./sentence.js";
import {Word} from "./word.js";

/**
 * @overview This class represents an example.
 */
export class Example {

    /**
     * Instantiates the example.
     * @param video {Video} The related video.
     * @param start_time {Number} The start timestamp (in sec) of the video where the word appeared in the sentence.
     * @param end_time {Number} The end timestamp (in sec) of the video where the word appeared in the sentence.
     * @param sentence {Sentence} The sentence containing the word.
     * @param word {Word} The word.
     */
    constructor(video, start_time, end_time, sentence, word) {
        this._video = video;
        this._start_time = start_time;
        this._end_time = end_time;
        this._sentence = sentence;
        this._word = word;
    }

    /**
     * Returns the video.
     * @returns {Video} The video.
     */
    getVideo() {
        return this._video;
    }

    /**
     * Returns the start timestamp.
     * @returns {Number} The start timestamp.
     */
    getStartTime() {
        return this._start_time;
    }

    /**
     * Returns the end timestamp.
     * @returns {Number} The end timestamp.
     */
    getEndTime() {
        return this._end_time;
    }

    /**
     * Returns the sentence.
     * @returns {Sentence} The sentence.
     */
    getSentence() {
        return this._sentence;
    }

    /**
     * Returns the word.
     * @returns {Word} The word.
     */
    getWord() {
        return this._word;
    }

    /**
     * Revive a JavaScript object as an example object.
     * @param object {Object} The given JavaScript object.
     * @return {Example} The related example object.
     * @throws {Error} In the case of an invalid object format.
     */
    static revive(object) {
        try {
            if (object.hasOwnProperty("_video")
                && object.hasOwnProperty("_start_time")
                && object.hasOwnProperty("_end_time")
                && object.hasOwnProperty("_sentence")
                && object.hasOwnProperty("_word")) {
                return new Example(
                    Video.revive(object._video),
                    object._start_time,
                    object._end_time,
                    Sentence.revive(object._sentence),
                    Word.revive(object._word)
                );
            } else {
                throw new Error(INVALID_OBJECT_FORMAT);
            }
        } catch (ex) {
            throw new Error(INVALID_OBJECT_FORMAT);
        }
    }

    /**
     * Prints the object in a human-readable way (JSON).
     */
    toString() {
        return JSON.stringify(this);
    }
}
