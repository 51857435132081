import {INVALID_OBJECT_FORMAT} from "../utils/model_constants.js";
import {Gloss} from "./gloss.js";
import {Word} from "./word.js";

/**
 * @overview This class represents a prediction.
 */
export class Prediction {

    /**
     * Instantiates the prediction.
     * @param gloss {Gloss} The related gloss.
     * @param fidelity {Number} The fidelity of the prediction.
     */
    constructor(gloss, fidelity) {
        this._gloss = gloss;
        this._fidelity = fidelity;
    }

    /**
     * Returns the gloss.
     * @returns {Gloss} The gloss.
     */
    getGloss() {
        return this._gloss;
    }

    /**
     * Returns the fidelity.
     * @returns {Word} The fidelity.
     */
    getFidelity() {
        return this._fidelity;
    }

    /**
     * Revive a JavaScript object as a prediction object.
     * @param object {Object} The given JavaScript object.
     * @return {Prediction} The related prediction object.
     * @throws {Error} In the case of an invalid object format.
     */
    static revive(object) {
        try {
            if (object.hasOwnProperty("_gloss")
                && object.hasOwnProperty("_fidelity")) {
                return new Prediction(
                    Gloss.revive(object._gloss),
                    object._fidelity
                );
            } else {
                throw new Error(INVALID_OBJECT_FORMAT);
            }
        } catch (ex) {
            throw new Error(INVALID_OBJECT_FORMAT);
        }
    }

    /**
     * Prints the object in a human-readable way (JSON).
     */
    toString() {
        return JSON.stringify(this);
    }
}