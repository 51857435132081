let Coralogix = require("coralogix-logger");
import {v4 as uuidv4} from 'uuid';

/**
 * @overview This class contains utils methods for making logs.
 */
class Logger {

    constructor() {
        this._sessionId = uuidv4();
        const _config = new Coralogix.LoggerConfig({
            applicationName: "LSBFR Frontend",
            privateKey: "75db7517-8e9b-84d2-9b86-4e48d6e1e693",
            subsystemName: "[LSBFR Frontend][Session : " + this._sessionId + "]",
        });
        Coralogix.CoralogixLogger.configure(_config);
        this._logger = new Coralogix.CoralogixLogger("LSFBFR Logger");
    }

    /**
     * Logs.
     * @param severity The log severity (LOG_SEVERITY).
     * @param className The class name related.
     * @param methodName The method name related.
     * @param text The log text.
     */
    log(severity, className, methodName, text) {
        this._logger.addLog(new Coralogix.Log({
            severity: severity,
            className: className,
            methodName: methodName,
            text: text
        }));
    }
}

export const LOGGER = new Logger();

export const LOG_SEVERITY = {
    INFO: Coralogix.Severity.info,
    WARN: Coralogix.Severity.warning,
    ERROR: Coralogix.Severity.error,
    DEBUG: Coralogix.Severity.debug
};