import {INVALID_OBJECT_FORMAT} from "../utils/model_constants.js";
import {Pose} from "./pose.js";

/**
 * @overview This class represents a sign.
 */
export class Sign {

    /**
     * Instantiates the sign.
     * @param poses {[Pose]} The poses corresponding to the sign.
     */
    constructor(poses) {
        this._poses = poses;
    }

    /**
     * Returns the poses.
     * @returns {[Pose]} The poses.
     */
    getPoses() {
        return this._poses;
    }

    /**
     * Revive a JavaScript object as a sign object.
     * @param object {Object} The given JavaScript object.
     * @return {Sign} The related sign object.
     * @throws {Error} In the case of an invalid object format.
     */
    static revive(object) {
        try {
            if (object.hasOwnProperty("_poses")) {
                let poses = [];
                object._poses.forEach(pose => poses.push(Pose.revive(pose)));
                return new Sign(poses);
            } else {
                throw new Error(INVALID_OBJECT_FORMAT);
            }
        } catch (ex) {
            throw new Error(INVALID_OBJECT_FORMAT);
        }
    }

    /**
     * Prints the object in a human-readable way (JSON).
     */
    toString() {
        return JSON.stringify(this);
    }
}