import {Presenter} from "./js/presenter/presenter.js";
import {FakePresenter} from "./js/presenter/fake_presenter.js";
import {FakePresenterTestUx} from "./js/presenter/fake_presenter_testux.js";
import * as bootstrap from 'bootstrap'; // WARNING ! Do not remove this line.
import {LOG_SEVERITY, LOGGER} from "./js/utils/log";

// Presenter switching

if (process.env !== undefined && process.env.MODE === 'test') {
    LOGGER.log(LOG_SEVERITY.INFO, "Entrypoint", "Entrypoint", "Test mode enabled");
    const presenter = new FakePresenter();
} else  if (process.env !== undefined && process.env.MODE === 'testux') {
    LOGGER.log(LOG_SEVERITY.INFO, "Entrypoint", "Entrypoint", "Test UX mode enabled");
    const presenter = new FakePresenterTestUx();
} else {
    LOGGER.log(LOG_SEVERITY.INFO, "Entrypoint", "Entrypoint", "Test mode disabled");
    const presenter = new Presenter(); // Entry point of the application.
}