import {INVALID_OBJECT_FORMAT} from "../utils/model_constants.js";
import {Landmark} from "./landmark.js";

/**
 * @overview This class represents a pose.
 */
export class Pose {

    /**
     * Instantiates the pose.
     * @param landmarks {[Landmark]} The landmark corresponding to the pose.
     */
    constructor(landmarks) {
        this._landmarks = landmarks;
    }

    /**
     * Returns the landmarks.
     * @returns {[Landmark]} The landmarks.
     */
    getLandmarks() {
        return this._landmarks;
    }

    /**
     * Revive a JavaScript object as a pose object.
     * @param object {Object} The given JavaScript object.
     * @return {Pose} The related pose object.
     * @throws {Error} In the case of an invalid object format.
     */
    static revive(object) {
        try {
            if (object.hasOwnProperty("_landmarks")) {
                let landmarks = [];
                object._landmarks.forEach(landmark => landmarks.push(Landmark.revive(landmark)));
                return new Pose(landmarks);
            } else {
                throw new Error(INVALID_OBJECT_FORMAT);
            }
        } catch (ex) {
            throw new Error(INVALID_OBJECT_FORMAT);
        }
    }

    /**
     * Prints the object in a human-readable way (JSON).
     */
    toString() {
        return JSON.stringify(this);
    }
}