import {INVALID_OBJECT_FORMAT} from "../utils/model_constants.js";

/**
 * @overview This class represents a sentence.
 */
export class Sentence {

    /**
     * Instantiates a sentence.
     * @param text {String} The sentence text.
     */
    constructor(text) {
        this._text = text;
    }

    /**
     * Returns the sentence text.
     * @returns {String} The sentence text.
     */
    getText() {
        return this._text;
    }

    setText(text) {
        this._text = text;
    }

    /**
     * Revive a JavaScript object as a sentence object.
     * @param object {Object} The given JavaScript object.
     * @return {Sentence} The related sentence object.
     * @throws {Error} In the case of an invalid object format.
     */
    static revive(object) {
        if (object.hasOwnProperty("_text")) {
            return new Sentence(object._text);
        } else {
            throw new Error(INVALID_OBJECT_FORMAT);
        }
    }

    /**
     * Prints the object in a human-readable way (JSON).
     */
    toString() {
        return JSON.stringify(this);
    }
}
