// --- API ---
export const API_URL = 'https://dico.corpus-lsfb.be/api/'; // Prod
// export const API_URL = 'http://localhost:8083/'; // Local
export const API_URL_ROUTE_PREDICTION_LANDMARKS = 'predictions/sign/landmarks';
export const API_URL_ROUTE_ANNOTATION_GLOSS = 'annotation/gloss/';
export const API_URL_ROUTE_TRANSLATION_GLOSS = 'traduction/gloss/';
export const API_URL_ROUTE_TRANSLATION = 'traduction/';
export const API_URL_ROUTE_ALL_TRANSLATION = 'traduction/all/';
export const API_URL_ROUTE_ANNOTATION_SIGNS = 'annotation/signs/';
export const API_URL_ROUTE_ALL_INFLECTED_FORMS = 'traduction/inflected_forms/';
