import {INVALID_OBJECT_FORMAT} from "../utils/model_constants.js";

/**
 * @overview This class represents a gloss.
 */
export class Gloss {

    /**
     * Instantiates a gloss.
     * @param text {String} The gloss text.
     */
    constructor(text) {
        this._text = text;
    }

    /**
     * Returns the gloss text.
     * @returns {String} The gloss text.
     */
    getText() {
        return this._text;
    }

    /**
     * Sets the gloss text by the new one given.
     * @param newText The new text given.
     */
    setText(newText) {
        this._text = newText;
    }

    /**
     * Revive a JavaScript object as a gloss object.
     * @param object {Object} The given JavaScript object.
     * @return {Gloss} The related gloss object.
     * @throws {Error} In the case of an invalid object format.
     */
    static revive(object) {
        if (object.hasOwnProperty("_text")) {
            return new Gloss(object._text);
        } else {
            throw new Error(INVALID_OBJECT_FORMAT);
        }
    }

    /**
     * Prints the object in a human-readable way (JSON).
     */
    toString() {
        return JSON.stringify(this);
    }
}