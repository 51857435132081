import {View} from "../view/view.js"
import {Prediction} from "../model/prediction.js";
import {Landmark} from "../model/landmark.js";
import {Pose} from "../model/pose.js";
import {Sign} from "../model/sign.js";
import {Gif} from "../model/gif.js";
import {
    API_URL,
    API_URL_ROUTE_ANNOTATION_GLOSS,
    API_URL_ROUTE_PREDICTION_LANDMARKS,
    API_URL_ROUTE_TRANSLATION,
    API_URL_ROUTE_TRANSLATION_GLOSS,
    API_URL_ROUTE_ALL_TRANSLATION, API_URL_ROUTE_ALL_INFLECTED_FORMS
} from "../utils/presenter_constants";
import {Translation} from "../model/translation";
import {Example} from "../model/example";

const axios = require('axios').default;

/**
 * @overview This class ensure the intermediate between the view and the logic.
 */
export class Presenter {

    /**
     * Instantiates the model and the view.
     */
    constructor() {
        this.view = new View(this);
    }

    // --- Alerts ---

    /**
     * Alerts the user of a message.
     * @param {string} message A custom message.
     */
    alertUser(message) {
        this.view.alertUser(message);
    }

    // -- Signs --

    /**
     * Returns a prediction list based on the given video.
     * @param video {Object} The given video.
     * @return Promise<Any> The prediction list.
     */
    getPredictionsByVideo(video) {
        return new Promise((resolve, reject) => {
        });
    }

    /**
     * Returns a prediction list based on the given frames.
     * @param frames [Object] The given frames.
     * @return Promise<Any> The prediction list.
     */
    getPredictionsByFrames(frames) {
        let poses = [];
        frames.forEach(pose => {
            let landmarks = [];
            pose.forEach(landmark => {
                landmarks.push(new Landmark(landmark.name, landmark.x, landmark.y, landmark.z));
            });
            poses.push(new Pose(landmarks));
        });
        let sign = new Sign(poses);
        return new Promise((resolve, reject) => {
            axios.post(API_URL + API_URL_ROUTE_PREDICTION_LANDMARKS, sign)
                .then(function (response) {
                    let predictions = response.data._predictions;
                    predictions = Presenter.transformPredictions(predictions);
                    resolve(predictions);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    /**
     * Applies some transformations on the given predictions data.
     * @param predictions [Prediction] The given predictions.
     */
    static transformPredictions(predictions) {

        let toReturn = [];

        // Transforms to objects.
        toReturn = predictions.map(prediction => Prediction.revive(prediction));

        // Exclude some signs without semantic.
        let toExclude = ["GSIGN", "FSIGN"];
        toReturn = toReturn.filter(prediction => !toExclude.includes(prediction.getGloss().getText()));

        // Reduce to the simple form.
        let toReduce = {
            "MAIS(BA)": "MAIS",
            "APPELER*": "APPELER",
            "PETIT(BA)": "PETIT",
            "REGARDER*": "REGARDER",
            "AVEC.A": "AVEC",
            "SI(Y)": "SI",
            "NON(BA)": "NON",
            "OU-LIEU(FLATO)": "OU-LIEU"
        };
        toReturn.forEach(prediction => {
            if (toReduce.hasOwnProperty(prediction.getGloss().getText())) {
                prediction.getGloss().setText(toReduce[prediction.getGloss().getText()]);
            }
        });

        return toReturn;
    }

    /**
     * Returns the gif related to the given gloss.
     * @param prediction {Prediction} The given gloss.
     * @return Promise<Any> The related gif.
     */
    getGif(prediction) {
        return new Promise((resolve, reject) => {
            axios.get(API_URL + API_URL_ROUTE_ANNOTATION_GLOSS + prediction.getGloss().getText())
                .then(function (response) {
                    resolve(new Gif(response.data._url));
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    /**
     * Returns the translations related to the given prediction.
     * @param prediction {Prediction} The given prediction.
     * @return Promise<Any> A translation.
     */
    getTranslations(prediction) {
        return new Promise((resolve, reject) => {
            axios.get(API_URL + API_URL_ROUTE_TRANSLATION_GLOSS + Prediction.revive(prediction).getGloss().getText())
                .then(function (response) {
                    let translations = [];
                    for (let i = 0; i < response.data.length; i++) {
                        translations.push(Translation.revive(response.data[i]));
                    }
                    resolve(translations);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    /**
    * Returns the examples related to the given gloss.
    * @param gloss {String} The given gloss.
    * @return Promise<Any> Examples.
    */
    static getExamples(gloss) {
        return new Promise((resolve, reject) => {
            axios.get(API_URL + API_URL_ROUTE_ALL_TRANSLATION + gloss)
                .then(function (response) {
                    let examples = [];
                    for (let i = 0; i < response.data.length; i++) {
                        examples.push(Example.revive(response.data[i]));
                    }
                    resolve(examples);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    /**
    * Returns the examples related to the given translation.
    * @param translation {Translation} The given translation.
    * @return Promise<Any> Examples.
    */
    getExamples(translation) {
        return new Promise((resolve, reject) => {
            axios.post(API_URL + API_URL_ROUTE_TRANSLATION, translation)
                .then(function (response) {
                    let examples = [];
                    for (let i = 0; i < response.data.length; i++) {
                        examples.push(Example.revive(response.data[i]));
                    }
                    resolve(examples);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    /**
     * Returns the examples related to the given translation.
     * @param translation {Translation} The given translation.
     * @return Promise<Any> Examples.
     */
    async getHighlightedExamples(translation) {
        let inflected_forms;
        await (axios
                .get(API_URL + API_URL_ROUTE_ALL_INFLECTED_FORMS + translation.getWord().getText())
                .then(response => {
                    inflected_forms = response.data;
                })

        ).then((returnVal) => {

        }).catch(error => {
            console.log(error)
            this.error = "Une erreur inattendue est survenue.";
        })

        return new Promise((resolve, reject) => {
            axios.post(API_URL + API_URL_ROUTE_TRANSLATION, translation)
                .then(function (response) {
                    let examples = [];

                    for (let i = 0; i < response.data.length; i++) {
                        let example = Example.revive(response.data[i]);
                        Presenter.highlight(example, inflected_forms);
                        examples.push(example);
                    }
                    resolve(examples);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    /**
     * Hightlight given words present in an example
     * @param example {Example} The given example.
     * @param wordsToHighlight {Array<String>} The words to hightlight
     */
    static highlight(example, wordsToHighlight) {
        let text = example.getSentence().getText();
        for (let j = 0; j < wordsToHighlight.length; j++) {
            const word = wordsToHighlight[j];
            const upperWord = word.charAt(0).toUpperCase() + word.slice(1);
            let pattern = new RegExp("\\b" + word + "\\b");
            text = text.replace(pattern, "<mark class='highlight'>" + word + "</mark>");
            pattern = new RegExp("\\b" + upperWord + "\\b");
            text = text.replace(pattern, "<mark class='highlight'>" + upperWord + "</mark>");
        }
        example.getSentence().setText(text);
    }

}
