import {Gif} from "../model/gif.js";
import {Prediction} from "../model/prediction.js";
import {Gloss} from "../model/gloss.js";
import {Word} from "../model/word.js";
import {Presenter} from "../presenter/presenter.js";
import {Translation} from "../model/translation.js";
import {Example} from "../model/example.js";
import {Video} from "../model/video.js";
import {Sentence} from "../model/sentence.js";

/**
 * @overview This class ensure the intermediate between the view and the logic.
 */
export class FakePresenterTestUx extends Presenter {

    /**
     * Instantiates the model and the view.
     */
    constructor() {
        super();
    }

    // -- Signs --

    /**
     * Returns a fake prediction list based on the given fake video.
     * @param video {Object} The given fake video.
     * @return Promise<Any> A fake prediction list.
     */
    getPredictionsByVideo(video) {
        return new Promise((resolve, reject) => {
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve([
                    new Prediction(new Gloss("SOEUR"), 0.8),
                    new Prediction(new Gloss("BEAUCOUP"), 0.75),
                    new Prediction(new Gloss("IMPOSSIBLE"), 0.6),
                    new Prediction(new Gloss("POSSIBLE"), 0.6)
                ]);
            }, 3000);
        });
    }

    /**
     * Returns a fake prediction list based on the given landmarks.
     * @param landmarks {Object} The given landmarks.
     * @return Promise<Any> A fake prediction list.
     */
    getPredictionsByFrames(landmarks) {
        return new Promise((resolve, reject) => {
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve([
                    new Prediction(new Gloss("SOEUR"), 0.8),
                    new Prediction(new Gloss("BEAUCOUP"), 0.75),
                    new Prediction(new Gloss("IMPOSSIBLE"), 0.6),
                    new Prediction(new Gloss("POSSIBLE"), 0.6)
                ]);
            }, 3000);
        });
    }

    /**
     * Returns the fake gif related to the given prediction.
     * @param prediction {Prediction} The given prediction.
     * @return Promise<Any> A fake gif.
     */
    getGif(prediction) {
        return new Promise((resolve, reject) => {
            let toReturn = undefined;
            switch (prediction.getGloss().getText()) {
                case "SOEUR":
                    toReturn = new Gif("signe_58a375c822d6803964e6324d15ffd8de.gif");
                    break;
                case "BEAUCOUP":
                    toReturn = new Gif("signe_544001c6cdd6f00ae65efb21e7b6da80.gif");
                    break;
                case "IMPOSSIBLE":
                    toReturn = new Gif("signe_26199eff316e795f19f822608454ea5b.gif");
                    break;
                case "POSSIBLE":
                    toReturn = new Gif("signe_69d941dea51e779023beb3a2b87db1a6.gif");
                    break;
            }
            resolve(toReturn);
        });
    }

    /**
     * Returns the fake translations related to the given prediction.
     * @param prediction {Prediction} The given prediction.
     * @return Promise<Any> A fake translation.
     */
    getTranslations(prediction) {
        return new Promise((resolve, reject) => {
            let toReturn = [];
            switch (prediction.getGloss().getText()) {
                case "SOEUR":
                    toReturn = [
                        new Translation(new Gloss("SOEUR"), new Word("Soeur"), 0.75),
                        new Translation(new Gloss("SOEUR"), new Word("Soeurs"), 0.25),
                    ];
                    break;
                case "BEAUCOUP":
                    toReturn = [
                        new Translation(new Gloss("BEAUCOUP"), new Word("Beaucoup"), 0.9),
                        new Translation(new Gloss("BEAUCOUP"), new Word("Beaucoup de "), 0.5)
                    ];
                    break;
                case "IMPOSSIBLE":
                    toReturn = [
                        new Translation(new Gloss("IMPOSSIBLE"), new Word("Impossible"), 0.9)
                    ];
                    break;
                case "POSSIBLE":
                    toReturn = [
                        new Translation(new Gloss("POSSIBLE"), new Word("Possible"), 0.9),
                        new Translation(new Gloss("POSSIBLE"), new Word("Capable"), 0.2),
                        new Translation(new Gloss("POSSIBLE"), new Word("Possibles"), 0.2),
                        new Translation(new Gloss("POSSIBLE"), new Word("Possibilité"), 0.2)
                    ];
                    break;

            }
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve(toReturn);
            }, 3000);
        });
    }

    /**
     * Returns the fake examples related to the given translation.
     * @param translation {Translation} The given translation.
     * @return Promise<Any> Fake examples.
     */
    getExamples(translation) {
        return new Promise((resolve, reject) => {
            let toReturn = [];
            switch (translation.getWord().getText()) {
                case "Soeur":
                    toReturn = [
                        new Example(
                            new Video("videos/corpus/27/SD_CLSFBI2703A_S055_B.mp4"),
                            151,
                            165,
                            new Sentence("Donc ça, c'était le monde des sourds. En plus, ma soeur et moi, nous avons toujours été liées, on signait toutes les deux."),
                            new Word("Soeur")
                        )
                    ];
                    break;
                case "Soeurs":
                    toReturn = [
                        new Example(
                            new Video("videos/corpus/19/SD_CLSFBI1904A_S041_B.mp4"),
                            46,
                            55,
                            new Sentence("Quand je vois mes frères et soeurs par exemple, les Sourds, ils en ont l’habitude, ils n’y font pas attention."),
                            new Word("Soeurs")
                        )];
                    break;
                case "Impossible":
                    toReturn = [
                        new Example(
                            new Video("videos/corpus/23/SD_CLSFBI2309A_S005_B.mp4"),
                            91,
                            105,
                            new Sentence("C’est impossible . Normalement, il doit y avoir des bactéries qui font petit à petit pourrir les aliments"),
                            new Word("Impossible")
                        )];
                    break;
                default:
                    toReturn = [];
                    break;
            }
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve(toReturn);
            }, 3000);
        });
    }
}