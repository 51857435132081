import {INVALID_OBJECT_FORMAT} from "../utils/model_constants.js";

/**
 * @overview This class represents a landmark.
 */
export class Landmark {

    /**
     * Instantiates the landmark.
     * @param name {String} The landmark name.
     * @param x {int} The x-axis coordinate.
     * @param y {int} The y-axis coordinate.
     * @param z {Number} The z-axis coordinate.
     */
    constructor(name, x, y, z) {
        this._name = name;
        this._x = x;
        this._y = y;
        this._z = z;
    }

    /**
     * Returns the name of the landmark.
     * @returns {String} The name of the landmark.
     */
    getName() {
        return this._name;
    }

    /**
     * Returns the x-axis coordinate.
     * @returns {Number} The coordinate.
     */
    getX() {
        return this._x;
    }

    /**
     * Returns the y-axis coordinate.
     * @returns {Number} The coordinate.
     */
    getY() {
        return this._y;
    }

    /**
     * Returns the z-axis coordinate.
     * @returns {Number} The coordinate.
     */
    getZ() {
        return this._z;
    }

    /**
     * Revive a JavaScript object as a landmark object.
     * @param object {Object} The given JavaScript object.
     * @return {Landmark} The related landmark object.
     * @throws {Error} In the case of an invalid object format.
     */
    static revive(object) {
        if (object.hasOwnProperty("_name")
            && object.hasOwnProperty("_x")
            && object.hasOwnProperty("_y")
            && object.hasOwnProperty("_z")) {
            return new Landmark(object._name, object._x, object._y, object._z);
        } else {
            throw new Error(INVALID_OBJECT_FORMAT);
        }
    }

    /**
     * Prints the object in a human-readable way (JSON).
     */
    toString() {
        return JSON.stringify(this);
    }
}