import {INVALID_OBJECT_FORMAT} from "../utils/model_constants.js";
import {Gloss} from "./gloss.js";
import {Word} from "./word.js";

/**
 * @overview This class represents a translation.
 */
export class Translation {

    /**
     * Instantiates the translation.
     * @param gloss {Gloss} The related gloss.
     * @param word {Word} The related gloss.
     * @param frequency {Number} The frequency of the word translated.
     */
    constructor(gloss, word, frequency) {
        this._gloss = gloss;
        this._word = word;
        this._frequency = frequency;
    }

    /**
     * Returns the gloss.
     * @returns {Gloss} The gloss.
     */
    getGloss() {
        return this._gloss;
    }

    /**
     * Returns the word.
     * @returns {Word} The word.
     */
    getWord() {
        return this._word;
    }

    /**
     * Returns the word frequency.
     * @returns {Number} The word frequency.
     */
    getFrequency() {
        return this._frequency;
    }

    /**
     * Revive a JavaScript object as a translation object.
     * @param object {Object} The given JavaScript object.
     * @return {Translation} The related translation object.
     * @throws {Error} In the case of an invalid object format.
     */
    static revive(object) {
        try {
            if (object.hasOwnProperty("_gloss")
                && object.hasOwnProperty("_word")
                && object.hasOwnProperty("_frequency")) {
                return new Translation(
                    Gloss.revive(object._gloss),
                    Word.revive(object._word),
                    object._frequency
                );
            } else {
                throw new Error(INVALID_OBJECT_FORMAT);
            }
        } catch (ex) {
            throw new Error(INVALID_OBJECT_FORMAT);
        }
    }

    /**
     * Prints the object in a human-readable way (JSON).
     */
    toString() {
        return JSON.stringify(this);
    }
}