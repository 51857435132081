import {Gif} from "../model/gif.js";
import {Prediction} from "../model/prediction.js";
import {Gloss} from "../model/gloss.js";
import {Word} from "../model/word.js";
import {Presenter} from "../presenter/presenter.js";
import {Translation} from "../model/translation.js";
import {Example} from "../model/example.js";
import {Video} from "../model/video.js";
import {Sentence} from "../model/sentence.js";

/**
 * @overview This class ensure the intermediate between the view and the logic.
 */
export class FakePresenter extends Presenter {

    /**
     * Instantiates the model and the view.
     */
    constructor() {
        super();
    }

    // -- Signs --

    /**
     * Returns a fake prediction list based on the given fake video.
     * @param video {Object} The given fake video.
     * @return Promise<Any> A fake prediction list.
     */
    getPredictionsByVideo(video) {
        return new Promise((resolve, reject) => {
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve([
                    new Prediction(new Gloss("BONJOUR"), 0.8),
                    new Prediction(new Gloss("BONJOUR.SALUT"), 0.75),
                    new Prediction(new Gloss("SALUT"), 0.6),
                    new Prediction(new Gloss("SALUT.TETE"), 0.5)
                ]);
            }, 3000);
        });
    }

    /**
     * Returns a fake prediction list based on the given landmarks.
     * @param landmarks {Object} The given landmarks.
     * @return Promise<Any> A fake prediction list.
     */
    getPredictionsByFrames(landmarks) {
        return new Promise((resolve, reject) => {
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve([
                    new Prediction(new Gloss("BONJOUR"), 0.8),
                    new Prediction(new Gloss("BONJOUR.SALUT"), 0.75),
                    new Prediction(new Gloss("SALUT"), 0.6),
                    new Prediction(new Gloss("SALUT.TETE"), 0.5)
                ]);
            }, 3000);
        });
    }

    /**
     * Returns the fake gif related to the given prediction.
     * @param prediction {Prediction} The given prediction.
     * @return Promise<Any> A fake gif.
     */
    getGif(prediction) {
        return new Promise((resolve, reject) => {
            let toReturn = undefined;
            switch (prediction.getGloss().getText()) {
                case "BONJOUR":
                    toReturn = new Gif("signe_ca022c13de06cee23922ce5fcd0a96b9.gif");
                    break;
                case "BONJOUR.SALUT":
                    toReturn = new Gif("signe_7407df15ffd37f02e6815a43809c2dce.gif");
                    break;
                case "SALUT":
                    toReturn = new Gif("signe_fe194a8427619f3b3d64b8e33ba5117a.gif");
                    break;
                case "SALUT.TETE":
                    toReturn = new Gif("signe_fb0697872a6ae1c22aed2305f6f41999.gif");
                    break;
            }
            resolve(toReturn);
        });
    }

    /**
     * Returns the fake translations related to the given prediction.
     * @param prediction {Prediction} The given prediction.
     * @return Promise<Any> A fake translation.
     */
    getTranslations(prediction) {
        return new Promise((resolve, reject) => {
            let toReturn = [];
            switch (prediction.getGloss().getText()) {
                case "BONJOUR":
                    toReturn = [new Translation(new Gloss("BONJOUR"), new Word("Bonjour", 0.9))];
                    break;
                case "BONJOUR.SALUT":
                    toReturn = [
                        new Translation(new Gloss("BONJOUR.SALUT"), new Word("Bonjour"), 0.9),
                        new Translation(new Gloss("BONJOUR.SALUT"), new Word("Salut"), 0.7),
                        new Translation(new Gloss("BONJOUR.SALUT"), new Word("Non merci"), 0.4),
                        new Translation(new Gloss("BONJOUR.SALUT"), new Word("Merci"), 0.2)
                    ];
                    break;
                case "SALUT":
                    toReturn = [
                        new Translation(new Gloss("SALUT"), new Word("Salut"), 0.9),
                        new Translation(new Gloss("SALUT"), new Word("Bonjour"), 0.8),
                        new Translation(new Gloss("SALUT"), new Word("Hey"), 0.6)
                    ];
                    break;
                case "SALUT.TETE":
                    toReturn = [
                        new Translation(new Gloss("SALUT.TETE"), new Word("Bonjour"), 0.9),
                        new Translation(new Gloss("SALUT.TETE"), new Word("Salut"), 0.8),
                        new Translation(new Gloss("SALUT.TETE"), new Word("Hey"), 0.6),
                        new Translation(new Gloss("SALUT.TETE"), new Word("Hello"), 0.4)
                    ];
                    break;
            }
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve(toReturn);
            }, 3000);
        });
    }

    /**
     * Returns the fake examples related to the given translation.
     * @param translation {Translation} The given translation.
     * @return Promise<Any> Fake examples.
     */
    getExamples(translation) {
        return new Promise((resolve, reject) => {
            let toReturn = [];
            switch (translation.getWord().getText()) {
                case "Bonjour":
                    toReturn = [
                        new Example(
                            new Video("videos/corpus/3/SD_CLSFBI0301A_S007_B.mp4"),
                            12,
                            15,
                            new Sentence("Bonjour."),
                            new Word("Bonjour")
                        ),
                        new Example(
                            new Video("videos/corpus/3/SD_CLSFBI0313A_S008_B.mp4"),
                            656,
                            700,
                            new Sentence("C’est vrai ! Bon… Bonjour Monsieur le Ministre. Je suis ravi de pouvoir vous rencontrer."),
                            new Word("Bonjour")
                        )
                    ];
                    break;
                default:
                    toReturn = [];
                    break;
            }
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve(toReturn);
            }, 3000);
        });
    }
}